import React, { Component } from 'react'
import { navigate } from 'gatsby'

// Components
import Layout from '../layout/Layout.jsx'
import Header from '../components/Header'
import Card from '../components/Card.jsx'
import SelectBar from '../components/SelectBar.jsx'
import MpList from '../data/MPList'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mps: MpList,
      selectedMps: [],
      isFormVisible: false,
      isOpen: false,
      limit: 3,
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        postcode_number: '',
      },
      isWaitingForResponse: false,
      isFormSubmitted: false,
      triggerLimitNotification: false,
      referenceToggle: false,
      emailValid: true,
    }
  }

  componentDidMount() {
    let params = ''
    if (this.props.location.search) {
      params = this.props.location.search
    }
    navigate('/results' + params)
    // check if there's any active selections
    this.setState({ selectedMps: this.state.mps.filter(mp => mp.isActive) })

    // this.checkIfCrew()

    // check if voted
    // if (localStorage.getItem('voted')) {
    //   let params = ''
    //   if (this.props.location.search) {
    //     params = this.props.location.search
    //   }
    //   // navigate('/results' + params)
    // }
  }

  componentDidUpdate() {
    if (this.state.triggerLimitNotification) {
      this.turnOffLimitNotification = setTimeout(() => {
        this.setState({ triggerLimitNotification: false })
      }, 2000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.turnOffLimitNotification)
  }

  handleActiveState = selectedMp => {
    this.toggleActiveFlag(selectedMp)
    // because it tries to toggle first, selectedMp has to be active.
    if (selectedMp.isActive) {
      // adds from selectedMps state
      this.setState({
        selectedMps: [
          ...this.state.selectedMps,
          ...this.state.mps.filter(mp => mp.name === selectedMp.name),
        ],
      })
    } else {
      // removes to selectedMps state
      this.setState(prevState => ({
        selectedMps: prevState.selectedMps.filter(mp => mp.name !== selectedMp.name),
      }))
    }
  }

  toggleActiveFlag = selectedMp => {
    // exits function if limit exceeds
    if (!selectedMp.isActive && this.state.selectedMps.length >= this.state.limit) {
      this.setState({ triggerLimitNotification: true })
      return
    }
    const temp = this.state.mps
    temp.forEach(mp => {
      if (mp.name === selectedMp.name) {
        mp.isActive = !mp.isActive
      }
    })
    this.setState({ mps: temp })
  }

  handleTogglePetition = e => {
    this.setState(prevState => ({
      isFormVisible: !prevState.isFormVisible,
    }))
  }

  handleFormInputChange = e => {
    const name = e.target.name
    const value = e.target.value

    this.setState({
      formData: {
        ...this.state.formData,
        [name]: value,
      },
    })
  }

  validateField = (fieldName, value) => {
    let emailValid

    switch (fieldName) {
      case 'email':
        emailValid = value.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
        break
      default:
        break
    }
    return emailValid ? true : false
  }

  handleBlur = e => {
    const name = e.target.name
    const value = e.target.value

    this.setState({ emailValid: this.validateField(name, value) })
  }

  handleFormSubmit = e => {
    e.preventDefault()
    console.log('hello')
    const { email, first_name, last_name, postcode_number, mobile } = this.state.formData
    const url = 'https://www.getup.org.au/api/take_action_with_external'
    // const module_id = 36078
    // const page_id = 7119
    const module_id = 48793
    const page_id = 20217
    const external_source = 'vto'
    const vote_them_out_votes = this.state.selectedMps
      .map(mp => mp.name)
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '_')

    this.setState({ isWaitingForResponse: true })

    const payload = {
      module_id: module_id,
      page_id: page_id,
      external_source: external_source,
      custom_fields: {
        vote_them_out_votes,
      },
      email,
      first_name,
      last_name,
      postcode_number,
      mobile,
    }

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log(response)
        if (response.status === 200) {
          this.setState({ isWaitingForResponse: false, isFormSubmitted: true })
          localStorage.setItem('voted', true)
          let params = ''
          if (this.props.location.search) {
            params = this.props.location.search
          }
          navigate('/results' + params, { state: { isFormSubmitted: true } })
        } else {
          // todo: handle error
        }
      })
      .catch(console.error.bind(console))
  }

  // getParameterByName(name) {
  //   if (this.props.location.search) {
  //     const url = this.props.location.search
  //     name = name.replace(/[\[\]]/g, '\\$&')
  //     const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //       results = regex.exec(url)
  //     if (!results) return null
  //     if (!results[2]) return ''
  //     return decodeURIComponent(results[2].replace(/\+/g, ' '))
  //   }
  // }

  // checkIfCrew = () => {
  //   const value = this.getParameterByName('s')
  //   if (value === 'crew') {
  //     localStorage.setItem('crew', true)
  //   } else if (value === 'md') {
  //     localStorage.setItem('md', true)
  //   }
  // }

  render() {
    return <></>
    return (
      <Layout>
        <Header header={header} />
        <div className={'row fix-safari-z-index' + (this.state.isFormVisible && ' noscroll')}>
          {this.state.mps.map(mp => (
            <Card key={mp.name.toString()} mp={mp} onClick={this.handleActiveState} />
          ))}
        </div>
        <SelectBar
          mps={this.state.selectedMps}
          onNextClick={this.handleTogglePetition}
          isFormVisible={this.state.isFormVisible}
          removeSelectedMp={this.handleActiveState}
          onFormSubmit={this.handleFormSubmit}
          formData={this.state.formData}
          handleFormInputChange={this.handleFormInputChange}
          isWaitingForResponse={this.state.isWaitingForResponse}
          triggerLimitNotification={this.state.triggerLimitNotification}
          handleBlur={this.handleBlur}
          emailValid={this.state.emailValid}
        />
        <div className="row">
          <div className="small-12 columns">
            <hr className="accordion-top" />
            <a
              onClick={() => this.setState({ referenceToggle: !this.state.referenceToggle })}
              className="btn btn-small btn-grey "
            >
              References
            </a>
            <div class={this.state.referenceToggle ? ' accordion-body active' : 'accordion-body'}>
              <p>
                Immigration Minister Peter Dutton caught joking about the effect of climate change
                on Pacific islands, Sydney Morning Herald, 11 September 2015
              </p>
              <p>
                Tony Abbott considering police-in-schools policy for remote Indigenous communities,
                ABC News, 1 November 2018
              </p>
              <p>
                Here's Tony Abbott Cracking A Joke About “Invasion” In A Classroom Full Of
                Indigenous Kids, Junkee, 3 November 2018
              </p>
              <p>
                Tony Abbott's climate change speech in London reveals his true self, ABC News, 11
                October 2017
              </p>
              <p>Kevin Andrews attacks assisted death deal, The Australian, 27 June 2018</p>
              <p>
                Let’s Talk About Kevin Andrews and the Fact Australia Hasn’t Swapped PMs in 205
                Days, Vice, 7 April 2016
              </p>
              <p>The religious minority seizing power in the Liberal Party, The Age, 3 June 2018</p>
              <p>Kevin Andrews’ immigration crusade, Crikey, 21 July 2008</p>
              <p>
                MPs open new front against Turnbull over same-sex marriage plebiscite funding, The
                Guardian, 12 September 2016
              </p>
              <p>
                Family First, conservative Christians join the Victorian Liberals, The Age, 2
                December 2016
              </p>
              <p>
                Christian Porter defiant on Centrelink's 'robodebt' flaws: 'This is not a matter for
                apology', The Guardian, 22 June 2017
              </p>
              <p>
                Liberal MPs Tony Pasin and Nicolle Flint attacked for ‘disloyalty’, Adelaide Now, 27
                April 2018
              </p>
              <p>
                Lib MP Nicolle Flint growls at BEAR, calls it bank bashing, Australian Financial
                Review, 5 February 2018
              </p>
              <p>
                Nicolle Flint: It’s time hold our public broadcaster, the ABC, to account, Adelaide
                Now, 14 July 2014
              </p>
              <p>
                Pyne: I’ll take your seat if mine goes in redistribution, The Australian, 27 October
                2017
              </p>
              <p>
                Nicolle Flint: Wouldn’t you prefer to be a woman who can say I’m in this role
                because I was the best?, Adelaide Now, 22 December 2014
              </p>
              <p>
                Australia scrubbed from UN climate change report after government intervention, The
                Guardian, 27 May 2016
              </p>
              <p>
                We're paying $834 million more for health insurance but getting less, Sydney Morning
                Herald, 26 November 2018
              </p>
              <p>
                How making water safe from lead poisoning could cost households billions, Daily
                Telegraph, 26 November 2018
              </p>
              <p>
                Katherine mayor labels Greg Hunt ‘misogynist’ after f-bomb tirade, Herald Sun, 31
                May 2018
              </p>
              <p>
                My Health Record's privacy chief quits amid claims agency 'not listening', Sydney
                Morning Herald, 9 November 2018
              </p>
              <p>
                Coalition to allow government-backed loans to coalmines as banks hesitant, The
                Guardian, 11 September 2017
              </p>
              <p>
                Sending refugees to New Zealand 'a giant magnet' to people smugglers, Ciobo says,
                The Guardian, 4 November 2018
              </p>
              <p>
                The return of Joyce is the final insult to an angry electorate, Crikey, 18 October
                2018
              </p>
              <p>
                'These guys are crazy': Barnaby Joyce backs 'Nazi' expulsions after backtrack, The
                Guardian, 2 November 2018
              </p>
              <p>
                Australia says yes and Barnaby Joyce refuses to vote in Parliament of Australia,
                Inverell Times, 8 December 2017
              </p>
              <p>
                Forget Barnaby Joyce's affair - this is why he should not return to the leadership,
                Sydney Morning Herald, 31 October 2018
              </p>
              <p>
                Live exports: Barnaby Joyce said most sheep arrive fine or in better condition,
                Northern Daily Leader, 16 October 2018
              </p>
              <p>
                AFP drops Alan Tudge investigation over Centrelink information leak, ABC News, 8 May
                2017
              </p>
              <p>
                Welfare dependency 'poison' for jobless, human services minister says, The Guardian,
                26 May 2017
              </p>
              <p>
                Centrelink cashless welfare card trial costing taxpayers $10,000 per participant,
                ABC News, 2 May 2017
              </p>
              <p>Lucy Wicks, Open Australia</p>
              <p>
                Australia, we need to talk about Sunday penalty rates, says Frydenberg, Sydney
                Morning Herald, 27 September 2015
              </p>
              <p>
                Frydenberg takes on GetUp campaign to block energy plan, Sydney Morning Herald, 22
                July 2018
              </p>
              <p>Frydenberg and Abbott bump heads over energy policy, The Guardian, 29 May 2018</p>
              <p>
                Q&A: Indigenous Australians are tired of being spoken for, says actress Shareena
                Clanton, ABC News, 20 February 2018
              </p>
              <p>Scott Morrison's foul bet on torture, Sydney Morning Herald, 25 June 2014</p>
              <p>
                Charities, unions and arts bodies win reprieve from foreign influence register, The
                Guardian, 25 June 2018
              </p>
              <p>
                Former Army officer turned MP Andrew Hastie agrees with Bernardi, Australian
                Conservatives, 7 February 2018
              </p>
              <p>
                Andrew Hastie's use of US intelligence over bribery allegation 'a concern', Dreyfus
                says, The Guardian, 24 May 2018
              </p>
              <p>
                Coalition backbenchers unite to lobby for coal under banner of Monash Forum, The
                Guardian, 3 April 2018
              </p>
              <p>
                George Christensen reported to police over gun photo aimed at 'greenie' rivals, The
                Guardian, 19 February 2018
              </p>
              <p>
                A front door, not a back door: Dutton’s decryption laws explained, SBS News, 26
                November 2018
              </p>
              <p>Melbourne has a ‘law and order’ problem: Angus Taylor, Courier Mail</p>
              <p>
                Coalition could indemnify new coal projects against potential carbon price, The
                Guardian, 24 October 2018
              </p>
              <p>
                National low-level nuclear waste dump earmarked for Barndioota, near Wilpena Pound,
                Adelaide Now, 29 April 2016
              </p>
              <p>
                Communities at the centre of the radioactive waste management debate benefit from
                "goodwill" funding, The Transcontinental, 22 November 2018
              </p>
              <p>
                MH17: Liberal MP Craig Kelly angers colleagues with comments on Russia's role in
                accident, ABC News, 19 June 2018
              </p>
              <p>
                Five things to know about Craig Kelly, the conservative Liberal backbencher saved by
                Scott Morrison, ABC News, 3 December 2018
              </p>
              <p>
                Coalition MP tasked with housing affordability says 'highly paid job' is 'first
                step' to home ownership, Sydney Morning Herald, 21 February 2017
              </p>
              <p>
                Liberal MP Michael Sukkar pledges to crush party’s Left ‘termites’, Herald Sun, 23
                April 2018
              </p>
              <p>
                Michael Sukkar faces backlash over role in Liberal leadership crisis, Sydney Morning
                Herald, 28 August 2018
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const header = {
  title: "Let's vote out the hard right",
  subtitle: [
    "The hard right of the Coalition are holding back progress on the issues that matter most. Whether it's action on climate change, or evacuating the camps on Manus and Nauru – we won't see the change we need while the hard right still has power. ",
    "GetUp's gearing up to take on hard right MPs in the upcoming federal election. And we need your help deciding who to target. ",
  ],
  subtitleBold:
    'Which hard right MP do you want to see out of Parliament? You can choose up to three. Cast your vote today. ',
}

export default App
